/**
 * All Common Constants
 */

export const ICON_LIST = {
  icon1:
    '/assets/img/Map-Marker-Flag--Right-Chartreuse.png',
  icon2:
    '/assets/img/MapMarker_Marker_Outside_Chartreuse.png',
  icon3:
    '/assets/img/Map-Marker-Ball-Right-Azure.png',
  icon4:
    '/assets/img/Map-Marker-Marker-Outside-Pink.png',
};

/*
 * @file: index.js
 * @description: App Configration
 * @date: 10 June 2020
 * @author: Poonam
 * */

import React, { useEffect } from 'react';
import { PersistGate } from 'redux-persist/es/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { history } from './history';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routers from '../routers';
import { Provider } from 'react-redux';

import configureStore from '../config';

// import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/responsive-style.min.css';
import '../assets/css/colors/color-1.css';
import '../assets/style.css';
import '../assets/css/custom.css';
import '../assets/scss/style.scss';

/************ store configration *********/
const { persistor, store } = configureStore(history);

export default () => {
  return (
    <Provider store={store}>
      <PersistGate loading={'loading ...'} persistor={persistor}>
        {/* <PersistGate persistor={persistor}> */}
        <ConnectedRouter history={history}>
          <ToastContainer />
          <Routers {...store} />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

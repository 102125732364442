/*
 * @file: AppRoute.js
 * @description: Defined routers layouts
 * @date: 10 June 2020
 * @author: Poonam
 */

/************ React Pages according to layouts  *****************/

import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';


const loginData = localStorage.getItem('newobject');



const jsonData = JSON.parse(loginData)
// console.log(jsonData.success, 'jsonData');

const AppRoute = ({
  component: Component,
  layout: Layout,
  requireAuth,
  to = '/',
  store,
  type = 'private',
  ...rest
}) => (



  <Route
    {...rest}

    render={(props) => {


      // if (jsonData.success === false) {

      //   return <Redirect to="/" />
      // }
      const isLogin = requireAuth(store);

      const username = localStorage.getItem("name")

      if (isLogin && props.location.pathname === '/') {
        return (


          <Redirect
            to={{
              pathname: '/dashboard',
              state: { from: props.location },
            }}
          />
        );

      }


      // if (isLogin === false) {
      //   return (


      //     <Redirect
      //       to={{
      //         pathname: '/',
      //         state: { from: props.location },
      //       }}
      //     />
      //   );
      // }
      if (type === 'public') {
        return (
          <Layout>
            <Component {...props} />

          </Layout>
        );
      }
      return isLogin || props.location.pathname === '/' ? (
        <Layout>
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect
          to={{
            pathname: to,
            state: { from: props.location },
          }}
        />
      );
    }}

  />


);
const scoll = () => {
  window.scrollTo(0, 0);
}


export default AppRoute;

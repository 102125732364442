/*
 * @file: login.js
 * @description: Admin Login website
 * @date: 10 June 2020
 * @author: Poonam
 */

import React from 'react';
import Header from '../global/Header';

const DashboardLayout = ({ props }) => {
  return (
    <>
      <Header {...props} />
      {props.children}
    </>
  );
};

export default DashboardLayout;

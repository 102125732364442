/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from 'react';
import { LocalForm, Control, Errors } from 'react-redux-form';
import { useDispatch } from 'react-redux';
import { required, validateEmail } from '../../utilities/regex';
import { Button, Input, FormGroup, InputGroup } from 'reactstrap';
import logo from './../../assets/img/logo.png';
import { login } from '../../actions/user';
import { Link } from 'react-router-dom';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
  },
};

const LoginModal = ({ modal, toggleForgotModal }) => {
  const dispatch = useDispatch();
  const [eyes, setEyes] = useState({ password: false });

  return (
    <>
      <div
        className="modal fade loginmodal"
        id="loginModal"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="text-right">
              <i
                className="fa fa-times signupClose"
                data-dismiss="modal"
                aria-label="Close"
              ></i>
            </div>
            <button
              type="button"
              className="close d-none"
              id="closeLogin"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <div className="modal-login">
              <Link to="/dashboard">
                <img src={logo} className="modal-logo mt-4" />
              </Link>

              <div className="text-center color-white mb-4">
                <span>
                  <b>Log In</b>
                </span>
              </div>
              <LocalForm
                onSubmit={(values) => dispatch(login(values))}
                model="user"
                className="px-3 pb-3"
              >
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Control.text
                      type="text"
                      model=".username"
                      className="form-control rdRev"
                      placeholder="Email Address"
                      component={(props) => (
                        <Input
                          {...props}
                          placeholder="Email Address"
                          type="test"
                          autoComplete={0}
                        />
                      )}
                      validators={{ required, validateEmail }}
                    />
                  </InputGroup>
                  <Errors
                    model="user.username"
                    show={(field) => field.touched && !field.focus}
                    className="error"
                    messages={{
                      required: 'Please enter your email address.',
                      validateEmail: (val) => `${val} is not a valid email.`,
                    }}
                  />
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Control.text
                      type="password"
                      model=".password"
                      className="form-control rdRev"
                      placeholder="Password"
                      validators={{ required }}
                      component={(props) => (
                        <Input
                          {...props}
                          placeholder="Password"
                          type={eyes.password ? 'text' : 'password'}
                          // type="password"
                          autoComplete="new-password"
                        />
                      )}
                    />
                    {/* 
                    <i class="fa fa-eye eyeicon" aria-hidden="true"></i>
                    <i class="fa fa-eye-slash iconslash" aria-hidden="true"></i> */}
                    <i
                      className={
                        eyes.password
                          ? 'fa fa-eye eyeicon'
                          : 'fa fa-eye-slash iconslash'
                      }
                      onClick={() =>
                        setEyes({ ...eyes, password: !eyes.password })
                      }
                    ></i>
                  </InputGroup>

                  <Errors
                    model="user.password"
                    show={(field) => field.touched && !field.focus}
                    className="error"
                    messages={{
                      required: 'Please enter password.',
                      // validatePassword: val => "Enter valid password"
                    }}
                  />

                  <div className="text-right mt-1">
                    <a
                      className="text-primary"
                      data-toggle="modal"
                      data-target="#forgotModal"
                    >
                      Forgot Password?
                    </a>
                    {/* 
                    <a
                      className="text-primary"
                      data-toggle="modal"
                      data-target="#resetModal"
                    >
                      reset Password?
                    </a> */}
                  </div>
                </FormGroup>
                <div className="row">
                  <div className="col-12 mt-12 text-center mt-5">
                    <Button
                      className="btn btn-primary rounded"
                      style={{ width: '100%' }}
                    >
                      Log In
                    </Button>
                  </div>
                </div>
              </LocalForm>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginModal;

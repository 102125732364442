/*
 * @file: history.js
 * @description: History Configration
 * @date: 10 June 2020
 * @author: Poonam
 * */

import { createBrowserHistory } from 'history';
/*********** History function **************/
export const history = createBrowserHistory();

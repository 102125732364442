/*
 * @file: login.js
 * @description: Admin Login website
 * @date: 10 June 2020
 * @author: Poonam
 */

import React from 'react';

const LoginLayout = ({ props }) => {
  return <>{props.children}</>;
};

export default LoginLayout;

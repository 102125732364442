import React, { useState, useEffect, useRef } from 'react';
import MemberMedia from "./memberMedia";

function memberMediaPage() {
  return (
    <MemberMedia />
  );
}

export default memberMediaPage;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { acceptInvitations } from '../../actions/notifications';
import { history } from '../../main/history';

const AcceptInvite = (props) => {
  useEffect(() => {
    const token = props.user.access_token;
    const params = { inviteId: props.match.params.id };
    props.acceptInvitations(params, (res) => {
      if (res.success) {
        history.push('/dashboard');
      }
    });
  }, []);

  return <></>;
};

// export default ProjectModal;

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {
  // acceptInvitations,
})(AcceptInvite);
